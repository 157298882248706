import React from "react";

const SvgIconPinGrey = props => (
	<svg viewBox="0 0 37.3 53.4" {...props}>
		<path
			fill="currentColor"
			d="M31.5,6L31.5,6C24.4-1.1,12.9-1.1,5.8,6l0,0c-6.4,6.4-7.1,18.4-1.7,25.7l14.5,21l14.5-21 C38.7,24.5,37.9,12.4,31.5,6z M18.9,24.7c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S22.2,24.7,18.9,24.7z"
		/>
	</svg>
);

export default SvgIconPinGrey;
