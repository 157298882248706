import React from "react";

const SvgComponent = props => (
	<svg viewBox="0 0 14.85 15.99" {...props}>
		<path
			fill="currentColor"
			d="M18.87,9.43H17.65V8.6a1.1,1.1,0,0,0-1.1-1.1h0a1.1,1.1,0,0,0-1.1,1.1v.83H9.7V8.6a1.1,1.1,0,0,0-1.1-1.1h0a1.1,1.1,0,0,0-1.1,1.1v.83H6.23a1.1,1.1,0,0,0-1.1,1.1V22.38a1.1,1.1,0,0,0,1.1,1.1H18.87a1.1,1.1,0,0,0,1.1-1.1V10.54A1.1,1.1,0,0,0,18.87,9.43Zm-.75,12.31H7v-9H18.12Z"
			transform="translate(-5.13 -7.5)"
		/>
		<path
			fill="currentColor"
			d="M8.49 16.83h1.92a.18.18 0 0 0 .18-.18V14.73a.18.18 0 0 0-.18-.18H8.49a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 8.49 16.83zM11.59 16.83h1.92a.18.18 0 0 0 .18-.18V14.73a.18.18 0 0 0-.18-.18H11.59a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 11.59 16.83zM14.68 16.83H16.6a.18.18 0 0 0 .18-.18V14.73a.18.18 0 0 0-.18-.18H14.68a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 14.68 16.83zM8.49 19.92h1.92a.18.18 0 0 0 .18-.18V17.82a.18.18 0 0 0-.18-.18H8.49a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 8.49 19.92zM11.59 19.92h1.92a.18.18 0 0 0 .18-.18V17.82a.18.18 0 0 0-.18-.18H11.59a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 11.59 19.92zM14.68 19.92H16.6a.18.18 0 0 0 .18-.18V17.82a.18.18 0 0 0-.18-.18H14.68a.18.18 0 0 0-.18.18v1.92A.18.18 0 0 0 14.68 19.92z"
			transform="translate(-5.13 -7.5)"
		/>
	</svg>
);

export default SvgComponent;
