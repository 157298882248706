import React from "react";
import PropTypes from "prop-types";
import Slider, { Range } from "rc-slider";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./range-input.scss";

const RangeInput = ({
	range = true,
	defaultValue,
	min = 0,
	max,
	step = 1,
	label,
	onValueChange,
	tipFormatter,
}) => {
	return (
		<div className="range-input">
			<div className="range-input__label">{label}</div>
			<div className="range-input__element">
				{range ? (
					<Range
						defaultValue={defaultValue}
						allowCross={false}
						step={Number(step)}
						min={Number(min)}
						max={Number(max)}
						tipFormatter={tipFormatter}
						onAfterChange={value => {
							onValueChange(value);
						}}
					/>
				) : (
					<Slider
						defaultValue={defaultValue}
						step={Number(step)}
						min={Number(min)}
						max={Number(max)}
						tipFormatter={tipFormatter}
						onAfterChange={value => {
							onValueChange(value);
						}}
					/>
				)}
			</div>
		</div>
	);
};

RangeInput.propTypes = {
	defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	onValueChange: PropTypes.func,
	range: PropTypes.bool,
	tipFormatter: PropTypes.any,
};

export default registerStyle(style)(RangeInput);
