import React from "react";

const SvgComponent = props => (
	<svg viewBox="0 0 20.94 21.31" {...props} className="ico">
		<path
			fill="currentColor"
			d="M10.47 1.59l2.75 6.24 6.78.68-5.08 4.54 1.44 6.67-5.89-3.43-5.89 3.43 1.45-6.67L.94 8.51l6.78-.68 2.75-6.24z"
		/>
	</svg>
);

export default SvgComponent;
