import React from "react";

const SvgComponent = props => (
	<svg viewBox="0 0 20.94 21.31" {...props} className="ico">
		<defs>
			<style>{".prefix__cls-1{fill:currentColor}"}</style>
		</defs>
		<path
			className="prefix__cls-1"
			d="M10.47 1.59L7.72 7.83l-6.78.68 5.09 4.54-1.45 6.67 5.89-3.43V1.59z"
		/>
		<path
			className="prefix__cls-1"
			d="M10.47 3.45l2.06 4.68.18.4.43.05 5.09.51-3.81 3.41-.33.29.09.42 1.09 5-4.42-2.57-.38-.22-.38.22-4.42 2.57 1.09-5 .09-.42-.32-.29-3.82-3.41 5.09-.51.43-.05.18-.4 2.06-4.68m0-1.86L7.72 7.83l-6.78.68L6 13.06l-1.42 6.66 5.89-3.43 5.89 3.43-1.44-6.66L20 8.51l-6.78-.68-2.75-6.24z"
		/>
	</svg>
);

export default SvgComponent;
