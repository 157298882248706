import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import "./input.scss";

class Input extends React.PureComponent {
	render() {
		const {
			type = "text",
			label,
			name,
			id,
			field = {},
			disabled,
			isRequired = false,
			loading = false,
			note = false,
			...props
		} = this.props;

		const inputClassName = classNames({
			"control-group": true,
			"control-group--required": isRequired,
			"control-group--touched": field.active || field.value || loading,
			"control-group--error": field.touched && field.error,
			"control-group--disabled": disabled,
			"control-group--loading": loading,
		});

		return (
			<div className={inputClassName}>
				<label htmlFor={id} className="control-group__label">
					{label}
				</label>

				{loading ? (
					<div className="control-group__loader">
						<HorizontalDotLoader dotSize="3px" color="#3493dc" />
					</div>
				) : (
					<input
						{...reduxFormDomOnlyProps(field)}
						className="control-group__input"
						type={type}
						id={id}
						name={name}
						disabled={disabled}
						{...props}
					/>
				)}
				{note && <div className="control-group__note"> {note}</div>}
			</div>
		);
	}
}

Input.propTypes = {
	name: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	note: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	isRequired: PropTypes.bool,
	type: PropTypes.string,
	id: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
};

export default Input;
