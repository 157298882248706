import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./button.scss";

const Button = ({
	className,
	onClick,
	children,
	submit = false,
	design = "default",
	loading = false,
	loadingLabel,
	disabled = false,
	...props
}) => {
	const type = submit ? "submit" : "button";

	const loaderClassName = loading ? "button--loading" : "";

	const disabledClassName = disabled ? "button--disabled" : "";

	const loadingMessage = loadingLabel ? (
		loadingLabel
	) : (
		<FormattedMessage
			id="general.button.loading.label"
			description="Buttonloading label"
			defaultMessage="Loading ..."
		/>
	);

	const buttonLabel = loading ? loadingMessage : children;

	return (
		// eslint-disable-next-line react/button-has-type
		<button
			type={type}
			className={`button button--${design} ${
				className ? className : ""
			} ${loaderClassName} ${disabledClassName}`}
			onClick={onClick}
			disabled={disabled || loading}
			{...props}
		>
			<div className={`button__text`}>{buttonLabel}</div>
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	submit: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	loadingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	design: PropTypes.oneOf(["pure", "primary", "ying-yang"]),
};

export default registerStyle(style)(Button);
