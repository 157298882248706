import PropTypes from "prop-types";
import React from "react";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import "./Switch.scss";
import classNames from "classnames";

const Switch = ({ field, toggle, id, name, isRequired, children, ...props }) => {
	const onChange = event => {
		const value = event.target.checked;
		field.onChange(value);
		toggle(event);
	};

	const switchClassName = classNames({
		switch: true,
		"switch--required": isRequired,
		"switch--error": field.touched && field.error,
	});

	return (
		<div className={switchClassName}>
			<div className="switch__component">
				<input
					{...reduxFormDomOnlyProps(field)}
					id={id}
					name={name}
					type="checkbox"
					className="switch__input"
					onChange={onChange}
				/>
				<label htmlFor={id} className="switch__bullet" data-cy={props["data-cy"]} />
			</div>
			<label htmlFor={id} className="switch__label">
				{children}
			</label>
		</div>
	);
};

Switch.propTypes = {
	id: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	name: PropTypes.string,
	field: PropTypes.object,
	toggle: PropTypes.func,
	isRequired: PropTypes.bool,
	"data-cy": PropTypes.string,
};

Switch.defaultProps = {
	field: {},
	toggle: () => {},
};

export default Switch;
