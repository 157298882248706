import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./select.scss";

class Select extends React.Component {
	constructor() {
		super();
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const value = event.target.value;
		this.props.field.onChange(value);
		if (typeof this.props.onChange === "function") {
			this.props.onChange(event);
		}
	}

	render() {
		const {
			field = {},
			name,
			id,
			autoComplete,
			label,
			loading = false,
			children,
			isRequired = false,
			...restProps
		} = this.props;

		const selectClassName = classNames({
			select: true,
			"select--required": isRequired,
			"select--touched": ((field.active || field.value) && field.value !== "") || loading,
			"select--error": field.touched && field.error,
			"select--loading": loading,
		});

		return (
			<div className={selectClassName}>
				<label htmlFor={id} className="select__label">
					{label}
				</label>

				{loading ? (
					<div className="select__loader">
						<HorizontalDotLoader dotSize="3px" color="#3493dc" />
					</div>
				) : (
					<select
						id={id}
						className="select__input"
						{...reduxFormDomOnlyProps(field)}
						name={name}
						autoComplete={autoComplete}
						onChange={this.onChange}
						data-cy={restProps["data-cy"]}
					>
						{children}
					</select>
				)}
			</div>
		);
	}
}

Select.propTypes = {
	name: PropTypes.string,
	isRequired: PropTypes.bool,
	loading: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	autoComplete: PropTypes.string,
	field: PropTypes.object.isRequired,
};

export default registerStyle(style)(Select);
